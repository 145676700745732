<template>
  <div class="card card-hover-shadow idea-request-card" :class="{ singleRequest: singleRequest, 'show-filter': showFilter }">
    <div class="top-section justify-content-between">
      <div class="d-flex align-items-center top-section__content">
        <StatusComponent
          v-if="showFilter || singleRequest"
          class="me-3"
          :text="requestData.priority.priorityName"
          :type="requestData.priority.priorityClass"
        />
        <StatusComponent
          v-if="showFilter || singleRequest"
          class="me-3"
          :text="requestData.status.statusName"
          :type="requestData.status.statusClass"
        />
        <div class="top-section__name">
          <div class="top-section__name--img">
            <img :src="requestData.business_icon_path" alt="">
          </div>
          <span>{{ requestData.business_name }}</span>
        </div>
        <div class="top-section__user">
          <div class="top-section__user--img">
            <img :src="requestData.creator.icon_path" alt="">
          </div>
          <span>{{ requestData.creator.first_name }} {{ requestData.creator.last_name }}</span>
        </div>
        <div class="top-section__user">
          <div class="top-section__user--img">
            <img src="/media/buying/project-manager.svg" alt="">
          </div>
          <span>{{ requestData.creator.job_title }}</span>
        </div>
      </div>

      <p v-if="showFilter" class="text-gray mb-0">{{ $t('Created on') }} {{ moment(requestData.created_at).format("DD.MM.YYYY") }}</p>
      <p class="text-gray mb-0 mx-3" v-if="singleRequest">
        {{ $t('Created on') }} {{ moment(requestData.created_at).format("DD.MM.YYYY") }}
      </p>
    </div>
    <div class="d-xxl-flex justify-content-between align-items-center">
      <div class="d-flex flex-wrap justify-content-between short-info align-items-center w-100" v-if="!singleRequest">
        <div class="d-flex align-content-center" v-if="!showFilter">
          <StatusComponent
            class="me-3"
            :text="requestData.priority.priorityName"
            :type="requestData.priority.priorityClass"
          />

          <StatusComponent
            :text="requestData.status.statusName"
            :type="requestData.status.statusClass"
          />
          <p
            class="text-gray mb-0 mx-3"
          >
            {{ $t('Created on') }} {{ moment(requestData.created_at).format("DD.MM.YYYY") }}
          </p>
        </div>
        <div v-if="!singleRequest" class="col-md-1 px-0 d-flex align-items-end justify-content-end comment-section">
          <img width="18" src="/media/buying/icons/comment.svg" alt="">
          <p class="text-gray fw-bold mx-2 mb-1">{{ requestData.comments_count }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex row mx-0 mt-3">
      <div class="msg-block col-md-11 px-0">
        <router-link
          :to="`/bank/feedback/request/${requestData.id}`"
          class="cursor-pointer text-gray-800">
          <h4 class="title">
            {{ requestData.title }}
          </h4>
        </router-link>

        <p> {{ requestData.description }}</p>
      </div>
    </div>
    <hr v-if="!showFilter">
    <div
      v-if="!showFilter"
      class="d-xxl-flex justify-content-between align-items-center">
      <div class="d-flex flex-wrap  align-items-center">
        <p class="my-3 pe-5 me-5 border-end">{{ $t("ID") }} <b>{{ requestData.id }}</b></p>
        <p class="text-gray mx-3 my-3">{{ $t("Product Area") }}</p>
        <div class="d-flex flex-wrap w-lg-auto">
          <p v-for="(area, i) in requestData.product_areas" class="my-3">
            <span v-if="i < 3" class="text-with-bg me-3 my-2">{{ area }}</span>
          </p>
          <p v-if="requestData.product_areas.length > 3" class="my-3"><span
            class="text-with-bg me-3">+ {{ requestData.product_areas.length - 3 }}</span>
          </p>
        </div>
      </div>
      <template v-if="currentUser.can(BankUserPermissionTypeEnum.OPEN_CLOSE_REQUEST_IDEA)">
        <button
          v-if="!singleRequest && requestData.status.code === RequestStatusEnum.OPEN"
          class="open-close-btn close mb-0"
          :class="{ buttonLoading: buttonLoading }"
          @click="toggleRequestStatus(requestData.id)"
          :disabled="buttonLoading"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Closing") }}
          </template>
          <template v-else>
            <img src="/media/buying/icons/close-red.svg" class="me-3" alt="">
            {{ $t("Close Request") }}
          </template>
        </button>
        <button
          v-else-if="!singleRequest && requestData.status.code === RequestStatusEnum.CLOSED"
          class="open-close-btn open mb-0"
          :class="{ buttonLoading: buttonLoading }"
          @click="toggleRequestStatus(requestData.id)"
          :disabled="buttonLoading"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Opening") }}
          </template>
          <template v-else>
            {{ $t("Open Request") }}
          </template>
        </button>
      </template>
    </div>
    <template v-if="currentUser.can(BankUserPermissionTypeEnum.OPEN_CLOSE_REQUEST_IDEA)">
      <hr v-if="singleRequest">
      <div v-if="singleRequest" class="d-flex align-items-center">
        <button
          v-if="requestData.status.code === RequestStatusEnum.OPEN"
          class="open-close-btn close mb-0 me-5"
          :class="{ buttonLoading: buttonLoading }"
          @click="toggleRequestStatus(requestData.id)"
          :disabled="buttonLoading"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Closing") }}
          </template>
          <template v-else>
            <img src="/media/buying/icons/close-red.svg" class="me-3" alt="">
            {{ $t("Close Request") }}
          </template>
        </button>
        <button
          v-if="requestData.status.code === RequestStatusEnum.CLOSED"
          class="open-close-btn open mb-0 me-5"
          :class="{ buttonLoading: buttonLoading }"
          @click="toggleRequestStatus(requestData.id)"
          :disabled="buttonLoading"
        >
          <template v-if="buttonLoading">
            <span class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Opening") }}
          </template>
          <template v-else>
            {{ $t("Open Request") }}
          </template>
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import StatusComponent from "../../../shared-components/utils/StatusComponent";
import moment from "moment";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import store from "@/store";

export default {
  name: "BankRequestItem",

  components: {
    StatusComponent
  },
  props: [
    "showFilter",
    "requestData",
    "singleRequest",
  ],

  emits: ["handle-toggle-status"],

  data() {
    return {
      BankUserPermissionTypeEnum,
      moment: moment,
      RequestStatusEnum
    };
  },

  computed: {
    buttonLoading() {
      return this.$store.getters.getToggleRequestStatusLoading === this.requestData.id
    },
    currentUser() {
      return store.getters.currentUser;
    }
  },

  methods: {
    toggleRequestStatus(requestId) {
      this.$emit("handle-toggle-status", requestId);
    }
  }
};
</script>
<style lang="scss" scoped>
.idea-request-card {
  &.card {
    position: relative;

    .comment-section {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .top-section {
      background: #F7F7F7;
      border-radius: 12px 12px 0 0;
      margin: -18px -23px 0 -23px;
      padding: 10px 23px 16px 23px;
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      &__content {
        width: 100%;
        overflow: auto;
        white-space: pre;
        padding-bottom: 4px;
        margin-bottom: -4px;
      }

      > p.text-gray {
        white-space: pre;
        margin-left: 15px;
      }

      &__name {
        display: flex;
        align-items: center;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        width: max-content;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-left: 8px;
        }

        &--img {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }

      &__user {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &--img {
          margin-right: 6px;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
          color: #8B8B8B;
          opacity: 0.87;
        }
      }
    }

    &.show-filter {
      padding: 16px;

      .comment-section {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }

      .top-section {
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 10px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;

        .text-gray {
          font-size: 12px;
        }

        &__user {
          &--img {
            width: 16px;
            height: 16px;
          }

          span {
            font-size: 12px;
            line-height: 13px;
          }
        }

        &__name {
          border: none;
          margin-right: 0;

          span {
            font-size: 11.013px;
            line-height: 13px;
            margin-left: 4px;
          }

          &--img {
            width: 16px;
            height: 16px;
          }
        }

        .status-label {
          font-size: 11px;
          line-height: 13px;
        }
      }
    }

    &.singleRequest {
      .top-section {
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 10px;

        &__name {
          border: none;
          margin-right: 0;
          margin-left: 20px;
        }
      }
      @media (max-width: 600px) {
        .top-section {
          flex-direction: column;
          align-items: flex-start;
          &__content {
            margin-bottom: 8px;
          }
          > .text-gray {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .card-hover-shadow {
    padding-right: 30px;
  }

  .short-info {
    * {
      font-size: 12px;
    }

    .text-gray {
      font-size: 11px;
      line-height: 18px;
    }
  }

  .edit {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .msg-block {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #2B2B2B;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }
  }

  .creator-icon_path {
    border-radius: 50%;
    object-fit: cover;
    margin-left: 19px;
  }

  @media (max-width: 600px) {
    &.card {
      padding: 18px 15px;

      .top-section {
        padding: 10px 15px 10px 15px;
        margin: -18px -15px 15px -15px;
        &__name {
          padding-right: 8px;
          margin-right: 8px;

          &--img {
            display: flex;
            width: 15px;
            height: 15px;
          }
        }

        &__user {
          margin-right: 0;

          &:not(:last-child) {
            margin-right: 8px;
            padding-right: 8px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
          }

          span {
            margin-left: 6px;
          }

          &--img {
            display: flex;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
</style>
